<script setup>
const localePath = useLocalePath();

const props = defineProps({
  partners: Array,
  setCursor: Function,
  isProjects: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const { partners } = props;
</script>

<template>
  <div
    class="partners-container"
    @mouseenter="
      setCursor(null, null, {
        'background-color': '#FFC800',
        'z-index': '1',
        position: 'absolute',
      })
    "
  >
    <div
      v-if="!isProjects"
      class="flex justify-center p-12 lg:pt-16 txt-q relative z-40"
    >
      {{ $t("homepage.partners.title") }}
    </div>
    <div v-else class="flex justify-center p-12 lg:p-16">
      <div>
        <!--          TODO i18n-->
        <div class="txt-q text-center mb-2">
          {{ $t("projects.partners.title") }}
        </div>
        <div class="txt-l text-center">
          {{ $t("projects.partners.subtitle") }}
        </div>
        <div class="flex justify-center text-sm">
          <NuxtLinkLocale
            :to="
              localePath({ name: 'contact', query: { from: 'partnership' } })
            "
          >
            <button
              class="bg-transparent border rounded-full border-black text-black px-4 py-2 mt-6"
            >
              {{ $t("projects.partners.button") }}
            </button>
          </NuxtLinkLocale>
        </div>
      </div>
    </div>
    <div class="slider mb-10 lg:mb-16 relative z-40">
      <div class="slide-track2">
        <div
          v-for="partner in partners"
          :key="partner.id"
          class="mx-3 lg:mx-4 slide relative z-40"
        >
          <img
            v-if="partner.mediaDetails.sizes"
            :src="partner.mediaDetails.sizes[0].sourceUrl"
            class="relative z-40"
          />
        </div>
        <div
          v-for="partner in partners"
          :key="partner.id"
          class="mx-3 lg:mx-4 slide relative z-40"
        >
          <img
            v-if="partner.mediaDetails.sizes"
            :src="partner.mediaDetails.sizes[0].sourceUrl"
            class="relative z-40"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.partners-container {
  background-color: #efefef;
  color: #1a1a1a;
  overflow: hidden;

  img {
    //max-width: 240px;
    height: auto;
    width: 300px;
  }
}

@keyframes scroll2 {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-500px * 5));
  }
}
.slider {
  //height: 60px;
  position: relative;
  width: 100%;

  display: grid;
  place-items: center;
  overflow: hidden;
}

.slide-track2 {
  width: 100%;
  display: flex;
  animation: scroll2 45s linear infinite;
  justify-content: space-between;
}

.slide {
  width: 150px;
  height: auto;
  display: grid;
  place-items: center;
  transition: 0.5s;
}

@keyframes scroll2 {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(calc(-182px * 40));
  }
}

@media screen and (max-width: 768px) {
  .slide {
    width: 100px;
  }

  @keyframes scroll2 {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-124px * 40));
    }
  }
}

.partners-container {
  .txt-q {
    font-size: 28px;
  }
}
</style>
